import { Alert, Box, Button, CircularProgress, Container, Divider, Grid2, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Popover } from '@mui/material';
import React, { Component } from 'react';
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import momen from "moment";
import axios from 'axios';
import { Assignment, Mail, Refresh } from '@mui/icons-material';

class RekomendasiHSM extends Component {
    state = { 
        anchorEl: null,
        isMenuOpen: false,
        appNumber: Global.getAppProfile().waNumber,
        listRekomendasiHsm: [], 
        isLoadingRekomendasi: false,
        forceReload: 0
     } 

    handleClick = (event) => {
        console.log(event);
        if(this.props.layoutMode && this.props.layoutMode === "STATIC"){
            this.loadRekomendasiHsm();
        }else{
            this.setState({anchorEl: event.target, isMenuOpen: true, listRekomendasiHsm: []}, () => {
                this.loadRekomendasiHsm();
            });
        }
    };
    handleClose = (onClickMenu = false, hsmNameReadable = "") => {
        this.setState({anchorEl: null, isMenuOpen: false}, () => {
            if(onClickMenu === true){
                this.props.handleOpenHsmList(hsmNameReadable);
            }
        });
        // this.props.onOpenCreateOrder(messageText);
    };

    getContactInfo = (waNumber) => {
        console.log("INIPROPS", this.props)
        let params = {
            waNumber: waNumber
        } 
        axios
        .post(Api.getContactInfo(), params, Api.getRequestHeader())
        .then((response) => {
            let responseData = response.data;
            if(responseData.success === true){
                let contact = this.props.generateItemConversation(responseData.data);
                this.props.handleConversationSelected(contact);
            }
        })
        .catch((error) => {
            this.setState({ errorMessage: error.message });
            console.error("There was an error!", error);
        });
    }

    loadRekomendasiHsm = () => {
        
        this.setState({isLoadingRekomendasi: true}, () => {
            let params = {
                customerPhone: this.props.conversationData.fromNumber
            } 
            axios
            .post(Api.rekomendasiHsm(), params, Api.getRequestHeader())
            .then((response) => {
                let responseData = response.data;
                if(responseData.success === true){
                    this.setState({listRekomendasiHsm: responseData.data, isLoadingRekomendasi: false});
                    // let contact = this.props.generateItemConversation(responseData.data);
                    // this.props.handleConversationSelected(contact);
                }else{
                    this.setState({isLoadingRekomendasi: false});
                }
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message, isLoadingRekomendasi: false });
                console.error("There was an error!", error);
            });
        });
        
    }

    componentDidMount() {
        if(this.props.layoutMode && this.props.layoutMode === "STATIC"){
            this.loadRekomendasiHsm();
        }
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.conversationData?.fromNumber !== this.props.conversationData?.fromNumber){
            this.setState({listRekomendasiHsm: []}, () => {
                this.loadRekomendasiHsm();
            });
        }

        if(this.state.forceReload !== this.props.forceReload){
            this.setState({forceReload: this.props.forceReload}, () => {
                this.loadRekomendasiHsm();
            });
        }
    }

    render() { 
        return (
            <div>
                {/* <Alert variant="filled" severity="warning" action={
                    <Button variant='outlined' size="small" onClick={this.handleClick}>
                        Cek sekarang!
                    </Button>
                }>
                    Ada {this.props.listPesanBelumDibalas.length} customer yang pesannya belum kamu balas!!!
                </Alert> */}
                {
                    this.props.layoutMode !== "STATIC" && (
                        <div className="badge badge-primary" style={{cursor: "pointer"}} 
                            // onClick={() => {this.setState({['bottom']: true, drawerMode: this.DRAWER_MODE_CUSTOMER_PROFILE, orderMessageText: "" })}}
                            // onClick={this.toggleShowCustomerProfile}
                            onClick={this.handleClick}
                        >
                            <Mail />
                        </div>
                    )
                }

                {
                    this.props.layoutMode && this.props.layoutMode === "STATIC" && (
                        <Box sx={{position: "fixed", top: "40px", margin: "auto", width: "100%", border: "solid 0px"}}>
                            
                            <Box sx={{margin: "auto", width: "50%",  backgroundColor: "#D5DAE1", padding: "5px", opacity: 0.95, borderRadius: "5px"}}>
                                <Box sx={{backgroundColor: "#FFFFFF", padding: "5px", borderRadius: "5px"}}>
                                    {/* loading ... auto
                                    <Box onClick={() => this.handleClose(true, "wkwkwk")}>
                                        <Box>
                                            <Box sx={{fontWeight: "bold"}}>{"Nama HSM Beautified"}</Box>
                                            <Box sx={{fontSize: "12px"}}>{"Nama HSM"}</Box>
                                        </Box>
                                    </Box> */}
                                    <Box sx={{textAlign: "center"}}>
                                        <Grid2 container spacing={1}>
                                            <Grid2 size={10}>
                                                <strong>Rekomendasi HSM</strong>
                                            </Grid2>
                                            <Grid2 size={2}>
                                                <IconButton size='small' onClick={this.loadRekomendasiHsm}>
                                                    <Refresh />
                                                </IconButton>
                                            </Grid2>
                                        </Grid2>
                                        <Divider />
                                    </Box>
                                    {
                                        this.state.isLoadingRekomendasi && 
                                        <Box textAlign={"center"}>
                                            Loading ... <CircularProgress size={15} />
                                        </Box>
                                    }
                                    <List dense={true} sx={{width: "100%", margin: "0"}}>
                                        {
                                            this.state.listRekomendasiHsm?.length > 0 && this.state.listRekomendasiHsm?.map(item => {
                                                return (
                                                    <ListItem sx={{padding: 0}} dense={true} key={item.hsmName} onClick={() => this.handleClose(true, item.hsmNameReadable)}>
                                                        <ListItemButton sx={{padding: "0 5px 0 5px"}}>
                                                            {/* <ListItemIcon>
                                                                <Assignment />
                                                            </ListItemIcon> */}
                                                            <ListItemText 
                                                                primary={<Box sx={{fontWeight: "bold", fontSize: "12px"}}>{item.hsmNameReadable}</Box>} 
                                                                secondary={<Box>
                                                                    <Box sx={{padding: "0 10px 0 10px", marginBottom: "5px", fontSize: "12px"}}>{item.hsmName}</Box>
                                                                    <Divider />
                                                                </Box>} 
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                );
                                                return <Box onClick={() => this.handleClose(true, item.hsmNameReadable)}>
                                                    <Box>
                                                        <Box sx={{fontWeight: "bold"}}>{item.hsmNameReadable}</Box>
                                                        <Box sx={{fontSize: "12px"}}>{item.hsmName}</Box>
                                                    </Box>
                                                </Box>
                                            })
                                        }
                                        
                                    </List>
                                    
                                    </Box>
                                </Box>
                        </Box>
                    )
                }

                <Menu
                    id={"menu-pesan-belum-dibalas"}
                    anchorEl={this.state.anchorEl}
                    open={this.state.isMenuOpen}
                    onClose={this.handleClose}
                    MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    }}
                    slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&::before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        },
                      }}
                    //   transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    //   anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                >
                    
                    <MenuItem>
                        <Box>
                            <strong>Rekomendasi HSM</strong>
                            <Divider />
                        </Box>
                    </MenuItem>
                    {
                        this.state.isLoadingRekomendasi && 
                        <MenuItem>
                            Loading ... <CircularProgress size={15} />
                        </MenuItem>
                    }
                    {
                        this.state.listRekomendasiHsm?.length > 0 && this.state.listRekomendasiHsm?.map(item => {
                            return <MenuItem onClick={() => this.handleClose(true, item.hsmNameReadable)}>
                                <Box>
                                    <Box sx={{fontWeight: "bold"}}>{item.hsmNameReadable}</Box>
                                    <Box sx={{fontSize: "12px"}}>{item.hsmName}</Box>
                                </Box>
                            </MenuItem>
                        })
                    }
                </Menu>
                {/* <Popover 
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 50, left: 400 }}
                    open={true}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    >
                    The content of the Popover.
                </Popover> */}
            </div>
            
        );
    }
}
 
export default RekomendasiHSM;