import { Button, Menu, MenuItem, TextField, Snackbar, capitalize, Grid, IconButton, Fab, ToggleButtonGroup, ToggleButton, Tooltip, Box, Divider, LinearProgress } from '@mui/material';
import axios from 'axios';
import React, { Component } from 'react';
import "./TemplateMessage.css";
import Global from "../../customlib/Global";
import Api from "../../customlib/Api";
import ucwords from "ucwords";
import {Close, FileOpen, FirstPage, LastPage, NavigateBefore, NavigateNext, Refresh, Search, Send} from "@mui/icons-material";
import ReactLoading from 'react-loading';
import TemplateParamsInput from './TemplateParamsInput';
import md5 from 'md5';
import ReactPlayer from 'react-player';


class TemplateMessage extends Component {
    state = { 
        listSales: [],
        selectedSales: {id: 0, name: ""},//id, name
        listTemplates: [],
        listTemplatesDisplay: [],
        snackbarVisible: false,
        snackbarMessage: "",
        enableButtonAlihkan: false,
        isLoading: false,
        isSending: false,
        sendingTemplateId: 0,
        hsmImages: [],
        open: false,
        anchorEl: null,
        selectedHsm: null,
        selectedHsmImage: null,
        counter: null,
        paging: {},
        query: "",
        queryChangeApplied: true,
        isRekomendasiMode: false
    }

    getHsmTemplate = (url = null) => {

        this.setState(
            {isLoading: true}, 
            () => {
                var userData = Global.get();
                const messageData = this.props.messageData();
                const params = {
                    userId: userData.user.id,
                    waBussinesNumber: messageData.from,
                    appProfileId: Global.getAppProfile()._id,
                    loadUrl: url,
                    query: this.state.query
                };

                console.log("API URL : ", Api.getWaMessageTemplates());
                console.log("PARAMS : ", params);
                console.log("HEADER : ", Api.getRequestHeader());

                axios
                .post(Api.getWaMessageTemplates(), params, Api.getRequestHeader())
                .then((response) => {
                    
                    console.log("MyCustomerToday : ", response.data.data.templates);
                    var responseData = response.data.data;
                    var templates = responseData.templates;
                    var paging = responseData.paging;

                    templates.forEach(element => {
                        element.labelName = ucwords(element.name.replaceAll("_", " "));
                        let count = (element.structure.body.match(/{{/g) || []).length;
                        var params = [];
                        for(var i=0; i < count; i++){
                            params.push({paramValue : "", paramIndex: i, paramLabel: i+1});
                        }
                        element.params = params;
                    });

                    console.log("TEMPLATES : ", templates);

                    this.setState({listTemplates: templates, paging: paging, counter: responseData.counter, listTemplatesDisplay: templates, isLoading: false}, () => {console.log(this.state.listTemplates)});
                    // this.setState({listSales: response.data.data});
                })
                .catch((error) => {
                    this.setState({isLoading: false});
                    console.error("There was an error!", userData.user.id, error);
                });
            }
        );
        
    };

    saveUsedHsmRekomendasi = (customerPhone, customerName, hsmName) => {

        this.setState(
            {isLoading: true}, 
            () => {
                // var userData = Global.get();
                // const messageData = this.props.messageData();
                const params = {
                    customerPhone: customerPhone,
                    customerName: customerName,
                    hsmName: hsmName
                };

                axios
                .post(Api.saveUsedHsmRekomendasi(), params, Api.getRequestHeader())
                .then((response) => {
                    
                })
                .catch((error) => {
                    this.setState({isLoading: false});
                    console.error("There was an error!", error);
                });
            }
        );
        
    };

    getListHsmImages = (type) => {
        console.log("TYPE", type);
        // this.setState(
        //     {isLoading: false}, 
        //     () => {
                var userData = Global.get();
                axios
                .get(Api.getListHsmImages(type))
                .then((response) => {
                    
                    console.log("HSMIMAGES : ", response.data);
                    this.setState({hsmImages: response.data});
                    
                })
                .catch((error) => {
                    this.setState({isLoading: false});
                    console.error("There was an error! : HSMIMAGES ", userData.user.id, error);
                });
        //     }
        // );
        
    };

    handleShowSnackbar = (message) => {
        this.setState({snackbarVisible: true, snackbarMessage: message});
    }

    handleCloseSnackbar = () => {
        this.setState({snackbarVisible: false, snackbarMessage: ""});
    }

    handleSearchValueChange = (event) => {
        let query = event.target.value !== null && event.target.value !== undefined ? event.target.value : "";
        // console.log("QUERY : ", event.target.value);
        // const listTemplate = [...this.state.listTemplates];
        // let search = listTemplate.filter((template) => template.labelName.toLowerCase().includes(query.toLowerCase()));

        // if(search.length > 0){
        //     this.setState({listTemplates: listTemplate, listTemplatesDisplay: search});
        // }

        this.setState({query: query, queryChangeApplied: false});

    };

    componentDidMount() {
        let filterRekomendasi = this.props.filterHsmRekomendasi;
        if(filterRekomendasi && filterRekomendasi?.length > 0){
            this.setState({isRekomendasiMode: true, query: filterRekomendasi}, () => {
                this.getHsmTemplate();
            })
        }else{
            this.getHsmTemplate();
        }

        // this.getListHsmImages("image");
    }

    handleUpdateParamValue = (templateId, paramIndex, paramValue) => {
        console.log(templateId, paramIndex, paramValue);

        const clonedTemplates = [...this.state.listTemplates];
        let template = clonedTemplates.filter((template) => template.id===templateId);

        if(template.length > 0){
            template[0].params[paramIndex].paramValue = paramValue;
        }

        console.log("FOUNDTEMPLATE", template);

        this.setState({listTemplates: clonedTemplates},()=>{console.log("UPDATEDTEMPLATES : ", this.state.listTemplates);});

    }

    handleSendTemplate = (templateData) => {

        if(this.state.isSending){
            return;
        }

        console.log("SENDTHISTEMPLATE : ", templateData);
        // return;
        // console.log("MESSAGEDATA : ", this.props.messageData());

        const messageData = this.props.messageData();
        const templateParams = templateData.params;
        const templateHeader = templateData.header;
        let templateText = templateData.structure.body;
        let paramToSend = [];

        var i = 0;
        templateParams.forEach(param => {
            console.log("PARAMVALUE "+1, param);
            templateText = templateText.replaceAll("{{"+(i+1)+"}}", param.paramValue);
            paramToSend.push(param.paramValue);
            ++i;
        });

        if(templateHeader !== undefined && templateHeader.type === "IMAGE"){
            messageData.message.type = "IMAGE";
            messageData.message.fileName = templateHeader.imageUrl;
            messageData.message.caption = templateText;
        }
        else if(templateHeader !== undefined && templateHeader.type === "VIDEO"){
            messageData.message.type = "VIDEO";
            messageData.message.fileName = templateHeader.imageUrl;
            messageData.message.caption = templateText;
        }
        else if(templateHeader !== undefined && templateHeader.type === "DOCUMENT"){
            messageData.message.type = "DOCUMENT";
            messageData.message.fileName = templateHeader.imageUrl;
            messageData.message.caption = templateText;
        }
        else{
            messageData.message.text = templateText;
        }

        console.log("MESSAGEDATA_FINAL",templateData.name, messageData);
        // return;

        this.setState(
            {isLoading: false, isSending: true, sendingTemplateId: templateData.id}, 
            () => {

                let params = {
                    templateParameters : {
                        from: messageData.from,
                        to: messageData.to,
                        toName: messageData.contact.name,
                        templateName: templateData.name,
                        templateNameSpace: templateData.nameSpace,
                        templateLanguage: templateData.language,
                        placeholders: paramToSend,
                        fileName: templateHeader !== undefined ? templateHeader.description : "",
                        hsmCategory: templateData.category
                    },
                    message: messageData,
                    appProfileId: Global.getAppProfile()._id
                };

                if(Global.getAppProfile().appVendor === "QONTAK"){
                    params.templateParameters.templateId = templateData.id;
                    params.templateParameters.organizationId = templateData.organizationId;
                }

                if(templateHeader !== undefined){
                    if(templateHeader.type === "IMAGE"){
                        params.templateParameters.header = {
                            type: templateHeader.type,
                            mediaUrl: templateHeader.imageUrl
                        }
                    }
                    if(templateHeader.type === "VIDEO"){
                        params.templateParameters.header = {
                            type: templateHeader.type,
                            mediaUrl: templateHeader.imageUrl
                        }
                    }
                    if(templateHeader.type === "DOCUMENT"){
                        params.templateParameters.header = {
                            type: templateHeader.type,
                            mediaUrl: templateHeader.imageUrl
                        }
                    }
                }

                console.log("TEMPATEPARAMS : ",params);
                // return;
                var userData = Global.get();
                axios
                .post(Api.sendMessageTemplate(), params, Api.getRequestHeader())
                .then((response) => {
                    console.log("RESPONSE_TEMPLATE",response.data);
                    this.setState({isSending: false, sendingTemplateId: 0});
                    if(this.state.isRekomendasiMode){
                        response.data.data.messageData.reloadRekomendasi = true;
                        this.saveUsedHsmRekomendasi(messageData.to, messageData.contact.name, templateData.name);
                    }
                    this.props.pushMessageHandler(response.data.data.messageData);
                    this.props.onCloseDrawer();
                })
                .catch((error) => {
                    this.setState({isLoading: false, isSending: false, sendingTemplateId: 0});
                    console.error("There was an error!", userData.user.id, error);
                });
            }
        );

    }

    handleSelectHsmImage = (hsmImage, templateData) => {
        this.setState({open: false, anchorEl: null}, () => {

            // hsmImage = this.state.selectedHsmImage;
            templateData = this.state.selectedHsm;

            const clonedTemplates = [...this.state.listTemplates];
            let template = clonedTemplates.filter((template) => template._id === templateData._id);
            console.log("CHECK", templateData);
            // return;
    
            if(template.length > 0){
                template[0].header = {
                    type: templateData.structure.header.format,
                    imageUrl: Api.getLavenderUrl() + hsmImage.image,
                    description: hsmImage.name
                }
            }
    
            console.log("FOUNDTEMPLATE", template, "TEMPLATEDATA", templateData);
    
            this.setState({listTemplates: clonedTemplates},()=>{console.log("UPDATEDTEMPLATES : ", this.state.listTemplates);});
        });
    };

    handleSelectHsmVideo = (hsmImage, templateData) => {
        this.setState({open: false, anchorEl: null}, () => {

            // hsmImage = this.state.selectedHsmImage;
            templateData = this.state.selectedHsm;

            const clonedTemplates = [...this.state.listTemplates];
            let template = clonedTemplates.filter((template) => template._id === templateData._id);
    
            if(template.length > 0){
                template[0].header = {
                    type: "VIDEO",
                    // videoUrl: Api.getLavenderUrl() + hsmImage.image,
                    videoUrl: Api.getLavenderUrl() + "videos/08a12b53600665f78cc1071510327b62.mp4",
                    description: hsmImage.description
                }
            }
    
            console.log("FOUNDTEMPLATE", template, "TEMPLATEDATA", templateData);
    
            this.setState({listTemplates: clonedTemplates},()=>{console.log("UPDATEDTEMPLATES : ", this.state.listTemplates);});
        });
    };

    handleSelectHsmDocument = (hsmImage, templateData) => {
        this.setState({open: false, anchorEl: null}, () => {

            // hsmImage = this.state.selectedHsmImage;
            templateData = this.state.selectedHsm;

            const clonedTemplates = [...this.state.listTemplates];
            let template = clonedTemplates.filter((template) => template._id === templateData._id);
    
            if(template.length > 0){
                template[0].header = {
                    type: "IMAGE",
                    documentUrl: Api.getLavenderUrl() + hsmImage.image,
                    description: hsmImage.description
                }
            }
    
            console.log("FOUNDTEMPLATE", template, "TEMPLATEDATA", templateData);
    
            this.setState({listTemplates: clonedTemplates},()=>{console.log("UPDATEDTEMPLATES : ", this.state.listTemplates);});
        });
    };
    
    handleCloseListHsmImage = () => {
        this.setState({open: false, anchorEl: null}, () => {
            // this.setState({anchorEl: null});
        });
    };

    handleOpenListHsmImage = (event, selectedHsm) => {
        console.log("SELECTEDHSM", selectedHsm);
        let type = selectedHsm.structure.header.format.toLowerCase() ?? "image";
        this.getListHsmImages(type);
        this.setState({anchorEl: event.currentTarget, open: true, hsmImages: [], selectedHsm: selectedHsm});
    };

    onKeyDownHandler = (e) => {

        if (e.keyCode === 13) {
            this.getHsmTemplate();
            this.setState({queryChangeApplied: true});
        }
    };

    render() { 
        return ( 
            <div className="container">
                <div className="row">
                    <div className="col-10" style={{paddingTop: "5px"}}><h5>Kirim Pesan Template</h5></div>
                    <div className="col-1">
                        <Tooltip title="Close">
                            <IconButton size='small' onClick={this.props.onClose} color='error'><Close /></IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Divider color='primary' />
                <div className="" style={{marginTop: "10px"}}>
                    <Grid container spacing={1}>
                        <Grid item xs={10}>
                            <TextField
                                onKeyDown={this.onKeyDownHandler}
                                onChange={this.handleSearchValueChange}
                                label="Cari nama HSM"
                                type="text"
                                className="form-control"
                                variant="outlined"
                                size='small'
                                value={this.state.query}
                                disabled={this.state.isRekomendasiMode}
                            />
                            {
                                this.state.queryChangeApplied === false && (
                                    <Box sx={{fontSize: "10px", marginTop: "5px", color: "red"}}>Tekan <strong>ENTER</strong> atau <Search sx={{width: "15px"}} /> untuk apply pencarian</Box>
                                )
                            }
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={"Cari nama HSM"}>
                                <IconButton color='primary' disabled={this.state.isRekomendasiMode} onClick={() => this.getHsmTemplate()}><Search /></IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title={"Reload HSM"}>
                                <IconButton color='warning' onClick={() => this.getHsmTemplate()}><Refresh /></IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <div className='border border-primary rounded p-1 bg-info text-center text-white' style={{marginTop: "10px"}}>
                        <small>
                            <strong>Hari ini terkirim {this.state.counter?.counter ?? 0} HSM Marketing dari total {this.state.counter?.totalHsm ?? 0} HSM </strong>
                        </small>
                    </div>
                </div>
                {/* <br /> */}
                {/* <h5 style={{marginTop: 5, marginBottom: 15}} className="text-secondary">Pilih salah satu pesan berikut</h5> */}
                
                {/* <div style={{overflowY: "scroll", height:"100%", marginTop: "10px"}}> */}
                <div style={{ marginTop: "10px"}}>
                    {
                        
                        this.state.isLoading && 
                        (
                            // <div className="container text-center">
                            //     <div className="row">
                            //         <div className="col-3"></div>
                            //         <div className="col-6">
                                        // {/* <ReactLoading color="#FF0000" type="bubbles" width={50} height={10}/> loading... */}
                                        <LinearProgress sx={{marginBottom: "5px"}} />
                            //         </div>
                            //         <div className="col-3"></div>
                            //     </div>
                                
                            // </div>
                        )
                    } 
                    {
                        this.state.listTemplatesDisplay.map(
                            (template) => 

                                {
                                    console.log(template.status);
                                    return template.status === "APPROVED" ? (
                                        <div className="container border border-warning rounded" style={{marginBottom: 5}}>

                                            <div className="row">
                                                <div className="col-9">
                                                    <h5>
                                                        {ucwords(template.name.replaceAll("_", " "))}
                                                    </h5>
                                                    <span className={template.category === "MARKETING" ? "badge badge-danger" : "badge badge-success"}><small>{ucwords(template.category.replaceAll("_", " ").toLowerCase())}</small></span>
                                                </div>
                                                <div className="col-2 text-right">
                                                    
                                                    {
                                                        this.state.isSending && this.state.sendingTemplateId===template.id ? 
                                                        (
                                                            // <></>
                                                            <ReactLoading color="#FF0000" type="bubbles" width={50} height={10}/>
                                                        ) : 
                                                        (
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                size="small"
                                                                onClick={() => {this.handleSendTemplate(template)}}
                                                                // className={classes.button}
                                                                endIcon={ <Send />}
                                                                disabled={template.hsmEnabled === false}
                                                            >
                                                                Kirim
                                                            </Button>
                                                        )
                                                    }
                                                    
                                                    
                                                </div>
                                            </div>
                                            <hr />
                                            {template.structure.body}
                                            {/* <hr /> */}
                                            {
                                                template.structure.header !== undefined && template.structure.header.format === "IMAGE" ? 
                                                    (
                                                        <div className="container-fluid">
                                                            <hr />
                                                            {
                                                                template.hsmEnabled === false ? (<div className="btn btn-secondary btn-block disabled">
                                                                Pilih Gambar
                                                            </div>) : (<div className="btn btn-primary btn-block" onClick={(event) => this.handleOpenListHsmImage(event, template)}>
                                                                Pilih Gambar
                                                            </div>)
                                                            }
                                                            {/* <div className="btn btn-primary btn-block" onClick={(event) => this.handleOpenListHsmImage(event, template)}>
                                                                Pilih Gambar
                                                            </div> */}
                                                            <br />
                                                            <div className="container-fluid text-center">
                                                            {
                                                                template.header === undefined ? "Gambar belum dipilih" : 
                                                                (
                                                                    <div className='text-center'>
                                                                        <img src={template.header.imageUrl} style={{maxWidth: "100%", maxHeight: "200px"}} />
                                                                        <br />
                                                                        {template.header.description}
                                                                    </div>
                                                                )
                                                            }
                                                            </div>
                                                            
                                                            {/* <Menu
                                                                id="lock-menu"
                                                                anchorEl={this.state.anchorEl}
                                                                open={this.state.open}
                                                                onClose={this.handleCloseListHsmImage}
                                                                
                                                                // transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                                                                // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                                                > 
                                                                {
                                                                this.state.hsmImages.map((hsmImage) => {
                                                                    return (
                                                                    <MenuItem
                                                                        key={md5(hsmImage.id)}
                                                                        // disabled={index === 0}
                                                                        // selected={index === this.state.selectedIndex}
                                                                        onClick={(event) => this.handleSelectHsmImage(hsmImage, template)}
                                                                    >
                                                                        <img src={Api.getLavenderUrl() + hsmImage.image} style={{maxWidth: "150px", maxHeight: "150px"}} />
                                                                        <br />
                                                                        <div>
                                                                        {hsmImage.description}
                                                                        </div>
                                                                    </MenuItem>
                                                                    );
                                                                })
                                                                }
                                                                
                                                            </Menu> */}
                                                        </div>
                                                    ) : 
                                                    template.structure.header !== undefined && template.structure.header.format === "VIDEO" ? 
                                                    (
                                                        <div className="container-fluid">
                                                            <hr />
                                                            {
                                                                template.hsmEnabled === false ? (<div className="btn btn-secondary btn-block disabled">
                                                                Pilih Video
                                                            </div>) : (<div className="btn btn-primary btn-block" onClick={(event) => this.handleOpenListHsmImage(event, template)}>
                                                                Pilih Video
                                                            </div>)
                                                            }
                                                            <br />
                                                            <div className="container-fluid text-center">
                                                            {
                                                                template.header === undefined ? "Video belum dipilih" : 
                                                                (
                                                                    <div className='text-center'>
                                                                        {/* <img src={template.header.imageUrl} style={{maxWidth: "100%", maxHeight: "200px"}} /> */}
                                                                        {/* <img src={"https://lotus.prestisa.id/lavenger-backend/public/videos/08a12b53600665f78cc1071510327b62.mp4"} style={{maxWidth: "100%", maxHeight: "200px"}} /> */}
                                                                        {/* <ReactPlayer width="100%" style={{maxHeight: "250px"}} controls={true} url={data.message.fileName !== undefined && data.message.fileName.startsWith("http") ? data.message.fileName : Api.getBaseVideosUrl() + data.message.fileName} /> */}
                                                                        <ReactPlayer width="100%" style={{maxHeight: "250px"}} controls={true} url={template.header.imageUrl} />
                                                                        <br />
                                                                        {template.header.description}
                                                                    </div>
                                                                )
                                                            }
                                                            </div>
                                                            
                                                        </div>
                                                    ) : 
                                                    template.structure.header !== undefined && template.structure.header.format === "DOCUMENT" ? 
                                                    (
                                                        <div className="container-fluid">
                                                            <hr />
                                                            {
                                                                template.hsmEnabled === false ? (<div className="btn btn-secondary btn-block disabled">
                                                                Pilih Dokumen
                                                            </div>) : (<div className="btn btn-primary btn-block" onClick={(event) => this.handleOpenListHsmImage(event, template)}>
                                                                Pilih Dokumen
                                                            </div>)
                                                            }
                                                            
                                                            <br />
                                                            <div className="container-fluid text-center">
                                                            {
                                                                template.header === undefined ? "Dokumen belum dipilih" : 
                                                                (
                                                                    <div className='text-center'>
                                                                        <FileOpen />
                                                                        {/* <img src={template.header.imageUrl} style={{maxWidth: "100%", maxHeight: "200px"}} /> */}
                                                                        {/* <img src={"https://lotus.prestisa.id/lavenger-backend/public/videos/08a12b53600665f78cc1071510327b62.mp4"} style={{maxWidth: "100%", maxHeight: "200px"}} /> */}
                                                                        {/* <ReactPlayer width="100%" style={{maxHeight: "250px"}} controls={true} url={data.message.fileName !== undefined && data.message.fileName.startsWith("http") ? data.message.fileName : Api.getBaseVideosUrl() + data.message.fileName} /> */}
                                                                        {/* <ReactPlayer width="100%" style={{maxHeight: "250px"}} controls={true} url={template.header.imageUrl} /> */}
                                                                        <br />
                                                                        {template.header.description}
                                                                    </div>
                                                                )
                                                            }
                                                            </div>
                                                            
                                                        </div>
                                                    ) :
                                                    (<></>)
                                            }
                                            <hr />
                                            {
                                                template.params.map(
                                                    (param) => {
                                                        return (
                                                        <TemplateParamsInput 
                                                            templateData={template} 
                                                            paramData={param} 
                                                            onUpdateData={this.handleUpdateParamValue}
                                                            disabled={template.hsmEnabled === false}
                                                        />);
                                                    }
                                                )
                                            }
                                            
                                            {/* <div className="">
                                                <div className="row">
                                                    <div className="col-1">
                                                        <span>
                                                            <ReactLoading color="#FF0000" type="bubbles" width={50} height={10}/>
                                                        </span>
                                                    </div>
                                                    <div className="col-11" style={{paddingTop: 10, paddingLeft:25}}>
                                                        Sedang mengirim, mohon tunggu... 
                                                    </div>
                                                </div>
                                                
                                                <br />
                                                
                                            </div> */}

                                        </div>
                                    ) : (<></>)
                                }

                                
                            
                        )
                    }
                </div>

                <Menu
                    id="popup-hsmimages"
                    anchorEl={this.state.anchorEl}
                    open={this.state.open}
                    onClose={this.handleCloseListHsmImage}
                    
                    // transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    // anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    > 
                    {
                    this.state.hsmImages.map((hsmImage) => {
                        return (
                        <MenuItem
                            key={md5(hsmImage.image)}
                            // disabled={index === 0}
                            // selected={index === this.state.selectedIndex}
                            onClick={(event) => this.handleSelectHsmImage(hsmImage, {})}
                        >
                            <div style={{display: "block", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", justifyItems: "center"}}>
                            {
                                hsmImage.tipe === "image" ? (
                                    <img src={Api.getLavenderUrl() + hsmImage.image} style={{maxWidth: "150px", maxHeight: "150px"}} />
                                ) : 
                                hsmImage.tipe === "video" ? (
                                    <ReactPlayer width="100%" style={{maxHeight: "250px"}} controls={false} url={ Api.getLavenderUrl() + hsmImage.image} />
                                ) : 
                                hsmImage.tipe === "document" ? (
                                    <FileOpen />
                                ) : 
                                (
                                    <>Unknown Type</>
                                )
                            }
                            <div>
                                {hsmImage.name}
                            </div>
                            </div>
                            
                            <br />
                            
                        </MenuItem>
                        );
                    })
                    }
                    
                </Menu>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={this.state.snackbarVisible}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}
                    message={this.state.snackbarMessage}
                />

                {
                    !this.state.paging?.next && !this.state.paging?.previous ? (<></>) : 
                    (
                        <Fab variant="extended" size="small" color='' sx={{position: "fixed", bottom: 50, right: "4vw"}}>
                            <ToggleButtonGroup
                                size='small'
                                color="primary"
                                // value={alignment}
                                exclusive
                                // onChange={handleChange}
                                aria-label="Platform"
                                >
                                <ToggleButton value="first" disabled={!this.state.paging?.previous} onClick={() => this.getHsmTemplate()}>
                                    <Tooltip title="First">
                                        <FirstPage />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="prev" disabled={!this.state.paging?.previous} onClick={() => this.getHsmTemplate(this.state.paging?.previous)}>
                                    <Tooltip title="Previous">
                                        <NavigateBefore />
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value="next" disabled={!this.state.paging?.next} onClick={() => this.getHsmTemplate(this.state.paging?.next)}>
                                    <Tooltip title="Next">
                                        <NavigateNext />
                                    </Tooltip>
                                </ToggleButton>
                                {/* <ToggleButton value="last" disabled={!this.state.paging?.next === null} onClick={() => this.getHsmTemplate(this.state.paging?.next)}>
                                    <Tooltip title="Last">
                                        <LastPage />
                                    </Tooltip>
                                </ToggleButton> */}
                            </ToggleButtonGroup>
                        </Fab>
                    )
                }

            </div>
        );
    }
}
 
export default TemplateMessage;